import React, { useState } from "react";
import "./nav.css";
//import logo from "../../assets/Logo (1).svg";
import logo from "./newlogo.png"

import dashboard from "../../assets/dashboard.svg";
import test from "../../assets/testcase.svg";
import result from "../../assets/results.svg";
import settings from "../../assets/s.svg";
import exit from "../../assets/exit.svg";
import data from "../../assets/data.svg";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Zoom from "@mui/material/Zoom";
import chevronLeft from "./assets/collapse.svg";
import chevronRight from "./assets/expand.svg";
import reportIcon from "../../assets/reportIcon.svg";
import assetsIcon from "../../assets/assets.svg";
import suitsIcon from "../../assets/sidebar/Suits.svg";
import suitereportsIcon from "../../assets/suitereports.svg";
import genAiLogo from "../../pages/Testcase/genAiTestCase/genAiLogo.svg";
import { connect, useSelector } from "react-redux";
import { LuBot } from "react-icons/lu";
import { setExitingIfelse } from "../../pages/EcomTesting/Testaction/cardsActions";
import { findCardById } from "../../utils/cards_functions";
// import genAiLogo from "pages/";

const LeftNav = ({ cards, conditionActive, setExitingIfelse }) => {
   const userPrivileges = useSelector(
      (state) => state.permissions.userPrivileges
   );

   const { logout } = useAuth();
   const location = useLocation();
   const navigate = useNavigate();
   const [expandSideBar, setExpandSideBar] = useState(false);

   const checkIfelseIsEmpty = () => {
      if (conditionActive.length > 0) {
         return conditionActive.some((item) => {
            if (
               item.condition === "If" ||
               item.condition === "ElseIf" ||
               item.condition === "Else"
            ) {
               const card = findCardById(item.started_at, cards);
               if (card && card.sub_steps && card.sub_steps.length === 0) {
                  setExitingIfelse(item.started_at);
                  return item.started_at;
               }
            }
            return false;
         });
      }
      return false;
   };

   const handleLogOut = async () => {
      if (checkIfelseIsEmpty()) {
         console.log("checkIfelseIsEmpty4");
         return;
      }
      console.log("Logging out");
      logout();
   };
   const toggleExpand = () => {
      // console.log("Toggling");
      setExpandSideBar(!expandSideBar);
   };

   return (
      <div
         className={`app-container ${expandSideBar ? "expanded" : "collapsed"}`}
      >
         <div className="top-nav">
            <div className="logo-nav">
               <div
                  style={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     gap: "5px",
                  }}
                  onClick={() => {
                     navigate("/test-case");
                  }}
               >
                  <img className="nav-logo" src={logo} alt="icon" />
                  {expandSideBar && (
                     <div className="nav-logo-heading">BotGauge</div>
                  )}
               </div>
               <img
                  src={expandSideBar ? chevronLeft : chevronRight}
                  alt="icon"
                  onClick={toggleExpand}
               />
            </div>
            {/* <hr className="hr-line" /> */}
            <div className="nav-routes">
               <NavLink to="/dashboard">
                  <Tooltip
                     title="Dashboard"
                     disableHoverListener={expandSideBar}
                     TransitionComponent={Zoom}
                  >
                     <div
                        className={`nav-item ${
                           location.pathname === "/dashboard" ? "active" : ""
                        }`}
                     >
                        <div style={{ width: "24px" }}>
                           <img src={dashboard} alt="icon" />
                        </div>
                        {expandSideBar && (
                           <div className="nav-headings">Dashboard</div>
                        )}
                     </div>
                  </Tooltip>
               </NavLink>
               {expandSideBar && (
                  <div
                     className="nav-headings"
                     style={{
                        fontWeight: "400",
                        textAlign: "left",
                        width: "13rem",
                     }}
                  >
                     Planning
                  </div>
               )}
               <NavLink to="/test-case">
                  <Tooltip
                     title="Testcase"
                     disableHoverListener={expandSideBar}
                     TransitionComponent={Zoom}
                  >
                     <div
                        className={`nav-item ${
                           location.pathname === "/test-case" ? "active" : ""
                        }`}
                     >
                        <div style={{ width: "24px" }}>
                           <img src={test} alt="icon" />
                        </div>
                        {expandSideBar && (
                           <div className="nav-headings">Testcase</div>
                        )}
                     </div>
                  </Tooltip>
               </NavLink>
               {console.log("userPrivileges", userPrivileges)}
               {userPrivileges.viewEditPresentAI && (
                  <NavLink to="/presence-ai">
                     <Tooltip
                        title="Presence AI"
                        disableHoverListener={expandSideBar}
                        TransitionComponent={Zoom}
                     >
                        <div
                           className={`nav-item ${
                              location.pathname === "/presence-ai"
                                 ? "active"
                                 : ""
                           }`}
                        >
                           <div style={{ width: "24px" }}>
                              <img
                                 style={{ width: "24px" }}
                                 src={genAiLogo}
                                 alt=""
                              />
                           </div>
                           {expandSideBar && (
                              <div className="nav-headings">Presence AI</div>
                           )}
                        </div>
                     </Tooltip>
                  </NavLink>
               )}
               {userPrivileges.viewEditPresentAI && (
                  <NavLink to="/meet">
                     <Tooltip
                        title="Meet"
                        disableHoverListener={expandSideBar}
                        TransitionComponent={Zoom}
                     >
                        <div
                           className={`nav-item ${
                              location.pathname === "/meet" ? "active" : ""
                           }`}
                        >
                           <div
                              style={{
                                 width: "24px",
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "center",
                              }}
                           >
                              <LuBot size={26} style={{ color: "white" }} />
                           </div>
                           {expandSideBar && (
                              <div className="nav-headings">Meet</div>
                           )}
                        </div>
                     </Tooltip>
                  </NavLink>
               )}

               <NavLink to="/data">
                  <Tooltip
                     title="Data"
                     disableHoverListener={expandSideBar}
                     TransitionComponent={Zoom}
                  >
                     <div
                        className={`nav-item ${
                           location.pathname === "/data" ? "active" : ""
                        }`}
                     >
                        <div
                           style={{
                              width: "24px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                           }}
                        >
                           <img src={data} alt="icon" />
                        </div>
                        {expandSideBar && (
                           <div className="nav-headings">Data</div>
                        )}
                     </div>
                  </Tooltip>
               </NavLink>
               <NavLink to="/assets">
                  <Tooltip
                     title="Assets"
                     disableHoverListener={expandSideBar}
                     TransitionComponent={Zoom}
                  >
                     <div
                        className={`nav-item ${
                           location.pathname === "/assets" ? "active" : ""
                        }`}
                     >
                        <div
                           style={{
                              width: "24px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                           }}
                        >
                           <div style={{ width: "24px" }}>
                              <img src={assetsIcon} alt="icon" />
                           </div>
                        </div>
                        {expandSideBar && (
                           <div className="nav-headings">Assets</div>
                        )}
                     </div>
                  </Tooltip>
               </NavLink>
               {expandSideBar && (
                  <div
                     className="nav-headings"
                     style={{
                        fontWeight: "400",
                        textAlign: "left",
                        width: "13rem",
                     }}
                  >
                     Execution
                  </div>
               )}
               <NavLink to="/suites">
                  <Tooltip
                     title="Suites"
                     disableHoverListener={expandSideBar}
                     TransitionComponent={Zoom}
                  >
                     <div
                        className={`nav-item ${
                           location.pathname === "/suites" ? "active" : ""
                        }`}
                     >
                        <div style={{ width: "24px" }}>
                           <img src={suitsIcon} alt="icon" />
                        </div>
                        {expandSideBar && (
                           <div className="nav-headings">Suites</div>
                        )}
                     </div>
                  </Tooltip>
               </NavLink>
               {expandSideBar && (
                  <div
                     className="nav-headings"
                     style={{
                        fontWeight: "400",
                        textAlign: "left",
                        width: "13rem",
                     }}
                  >
                     Report
                  </div>
               )}
               <NavLink to="/execution">
                  <Tooltip
                     title="Test Case Report"
                     disableHoverListener={expandSideBar}
                     TransitionComponent={Zoom}
                  >
                     <div
                        className={`nav-item ${
                           location.pathname === "/execution" ? "active" : ""
                        }`}
                     >
                        <div style={{ width: "24px" }}>
                           <img src={reportIcon} alt="icon" />
                        </div>
                        {expandSideBar && (
                           <div className="nav-headings">Test Case Reports</div>
                        )}
                     </div>
                  </Tooltip>
               </NavLink>
               <NavLink to="/suite-reports">
                  <Tooltip
                     title="Suites Report"
                     disableHoverListener={expandSideBar}
                     TransitionComponent={Zoom}
                  >
                     <div
                        className={`nav-item ${
                           location.pathname === "/suite-reports"
                              ? "active"
                              : ""
                        }`}
                     >
                        <div style={{ width: "24px" }}>
                           <img src={suitereportsIcon} alt="icon" />
                        </div>
                        {expandSideBar && (
                           <div className="nav-headings">Suites Report</div>
                        )}
                     </div>
                  </Tooltip>
               </NavLink>
            </div>
         </div>
         <div className="bottom-nav">
            <NavLink to="/settings">
               <div
                  className={`nav-item-b ${
                     location.pathname === "/settings" ? "active" : ""
                  }`}
               >
                  <img src={settings} alt="icon" />
                  {expandSideBar && (
                     <div className="nav-headings">Settings</div>
                  )}
               </div>
            </NavLink>
            <Tooltip
               title="Log Out"
               disableHoverListener={expandSideBar}
               TransitionComponent={Zoom}
               placement="right"
            >
               <div className={`nav-item-b`} onClick={handleLogOut}>
                  <div
                     style={{
                        width: "24px",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                     }}
                  >
                     <img src={exit} alt="icon" />
                  </div>
                  {expandSideBar && <div className="nav-headings">Log Out</div>}
               </div>
            </Tooltip>
         </div>
      </div>
   );
};

const mapStateToProps = (state) => ({
   cards: state.cards.cards,
   conditionActive: state.cards.conditionActive,
});

const mapDispatchToProps = {
   setExitingIfelse,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftNav);
