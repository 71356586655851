import apiService from "./apiServices";

// global data
export const delete_global = async (key) => {
   try {
      const response = await apiService.delete(`/platform/global-data/${key}`);
      if (!response.status === 200 || !response.data) {
         throw new Error("Failed to delete global data");
      }
      return response.data;
   } catch (error) {
      console.error("Error deleting", error);
      throw error;
   }
};

export const create_globalData = async (data) => {
   try {
      const response = await apiService.post("/platform/global-data", data);
      return response.data;
   } catch (error) {
      console.error("POST request failed:", error);
      throw error;
   }
};

export const update_globalData = async (data) => {
   try {
      const response = await apiService.put("/platform/global-data", data);
      return response.data;
   } catch (error) {
      console.error("PUT request failed:", error);
      throw error;
   }
};

export const fetch_global = async (page = 1, pageSize = 20) => {
   try {
      return await apiService.get("/platform/global-data", {
         params: { page, pageSize },
      });
   } catch (error) {
      console.error("Failed to fetch global data", error);
      throw error;
   }
};

// local data
export const fetch_local = async () => {
   try {
      return await apiService.get("/platform/local-data");
   } catch (error) {
      console.error("Failed to fetch local data", error);
      throw error;
   }
};

export const download_local_data = async (key) => {
   try {
      const response = await apiService.get(
         `/platform/local-data/download/${key}`,
         {
            responseType: "arraybuffer",
         }
      );
      if (!response.status === 200 || !response.data) {
         throw new Error("Failed to download local data");
      }
      return response.data;
   } catch (error) {
      console.error("Error while downloading local data", error);
      throw error;
   }
};

export const delete_local = async (key) => {
   try {
      const response = await apiService.delete(`/platform/local-data/${key}`);
      if (!response.status === 200 || !response.data) {
         throw new Error("Failed to delete local data");
      }
      return response.data;
   } catch (error) {
      console.error("Error deleting", error);
      throw error;
   }
};

export const create_localData = async (formData) => {
   try {
      const response = await apiService.post("/platform/local-data", formData, {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      });
      return response.data;
   } catch (error) {
      console.error("POST request failed:", error);
      throw error;
   }
};

export const fetch_localData_Col = async (localdataId) => {
   try {
      const response = await apiService.get(
         `/platform/local-data/by-key/${localdataId}/columns`
      );
      return response.data;
   } catch (error) {
      console.error("GET request failed:", error);
      throw error;
   }
};

export const fetch_localdata_linked_testcases = async () => {
   try {
      const response = await apiService.get(
         `/platform/local-data/test-case-mappings`
      );
      return response.data;
   } catch (error) {
      console.error("Error fetching Linked Test cases:", error);
      throw error;
   }
};

// Table data
export const fetch_localdata_by_id = async (localDataId) => {
   try {
      const response = await apiService.get(
         `/platform/local-data/by-key/${localDataId}`
      );
      return response.data;
   } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
   }
};

export const rename_local = async (localDataId, fileName) => {
   try {
      const response = await apiService.post(`/platform/local-data/rename`, {
         local_data_id: localDataId,
         new_name: fileName,
      });
      return response.data;
   } catch (error) {
      console.error("Error renaming", error);
      throw error;
   }
};

export const add_Column = async (localDataId, newColumn) => {
   try {
      const response = await apiService.put(`/platform/local-data`, {
         local_data_id: localDataId,
         updates: [newColumn],
      });
      return response.data;
   } catch (error) {
      console.error("Error adding column to the left:", error);
      throw error;
   }
};

export const add_row = async (localDataId, newRow) => {
   try {
      const response = await apiService.put(`/platform/local-data`, {
         local_data_id: localDataId,
         updates: [newRow],
      });
      return response.data;
   } catch (error) {
      console.error("Error adding row", error);
      throw error;
   }
};

export const change_row_order = async (localDataId, fromIndex, toIndex) => {
   try {
      const response = await apiService.put(`/platform/local-data`, {
         local_data_id: localDataId,
         updates: [
            {
               type: "ChangeRowOrder",
               current_index: fromIndex,
               new_index: toIndex,
            },
         ],
      });
      return response.data;
   } catch (error) {
      console.error("Error changing row order:", error);
      throw error;
   }
};

export const change_column_order = async (localDataId, fromIndex, toIndex) => {
   try {
      const response = await apiService.put(`/platform/local-data`, {
         local_data_id: localDataId,
         updates: [
            {
               type: "ChangeColumnOrder",
               current_index: fromIndex,
               new_index: toIndex,
            },
         ],
      });
      return response.data;
   } catch (error) {
      console.error("Error changing column order:", error);
      throw error;
   }
};

export const delete_row = async (localDataId, rowIndex) => {
   try {
      const response = await apiService.put(`/platform/local-data`, {
         local_data_id: localDataId,
         updates: [
            {
               type: "DeleteRow",
               row_index: rowIndex,
            },
         ],
      });
      return response.data;
   } catch (error) {
      console.error("Error deleting Row", error);
      throw error;
   }
};

export const delete_column = async (localDataId, columnIndex) => {
   try {
      const response = await apiService.put(`/platform/local-data`, {
         local_data_id: localDataId,
         updates: [
            {
               type: "DeleteColumn",
               column_index: columnIndex,
            },
         ],
      });
      return response.data;
   } catch (error) {
      console.error("Error deleting column:", error);
      throw error;
   }
};

export const save_cell_value = async (
   localDataId,
   rowIndex,
   colIndex,
   value
) => {
   try {
      const response = await apiService.put(`/platform/local-data`, {
         local_data_id: localDataId,
         updates: [
            {
               type: "AddCell",
               row_index: rowIndex,
               column_index: colIndex,
               value: value,
            },
         ],
      });
      return response.data;
   } catch (error) {
      console.error("Error updating cell value:", error);
      throw error;
   }
};

// linked data
