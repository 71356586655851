import apiService from "./apiServices";

// Unified error handling function
const handleApiError = (error, context) => {
   console.error(`Error ${context}:`, error);
   throw error;
};

// Company Management

export const getAllCompanies = async () => {
   try {
      const response = await apiService.get("/platform/v1/companies");
      return response.data;
   } catch (error) {
      handleApiError(error, "fetching all companies");
   }
};

export const createCompany = async (companyDetails) => {
   try {
      const response = await apiService.post(
         "/platform/v1/companies",
         companyDetails
      );
      return response.data;
   } catch (error) {
      handleApiError(error, "creating new company");
   }
};

export const updateCompanyDetails = async (id, companyDetails) => {
   try {
      const response = await apiService.patch(
         `/platform/v1/companies/${id}`,
         companyDetails
      );
      return response.data;
   } catch (error) {
      handleApiError(error, "updating company details");
   }
};

export const updateCompanyStatus = async (id, status) => {
   try {
      const response = await apiService.patch(`/platform/v1/companies/${id}`, {
         status,
      });
      return response.data;
   } catch (error) {
      handleApiError(error, "updating company status");
   }
};

export const searchCompaniesByName = async (companyName) => {
   try {
      const response = await apiService.get(`/platform/v1/companies/search`, {
         params: { company_name: companyName },
      });
      return response.data;
   } catch (error) {
      handleApiError(error, "searching companies by name");
   }
};

// User Management

export const addUser = async (userDetail) => {
   try {
      const response = await apiService.post(
         "/platform/create-user",
         userDetail
      );
      return response.data;
   } catch (error) {
      handleApiError(error, "adding user in user management");
   }
};

export const getUser = async () => {
   try {
      const response = await apiService.get(`/platform/get_all_user`);
      return response.data;
   } catch (error) {
      handleApiError(error, "fetching user data in user management");
   }
};

export const cloneTestCase = async (data) => {
   try {
      const response = await apiService.post("/platform/clone_testcase/", data);
      return response.data;
   } catch (error) {
      handleApiError(error, "cloning test case");
   }
};

export const PurgeQueues = async () => {
   try {
      const response = await apiService.post(
         "/platform/maintenance/purge-execution-queue"
      );
      return response;
   } catch (error) {
      console.error(error, "Error purging execution queue");
   }
};

export const PurgeActiveSessions = async () => {
   try {
      const response = await apiService.post(
         "/platform/maintenance/reset-worker-counts"
      );
      return response;
   } catch (error) {
      console.error(error, "Error resetting worker counts");
   }
};
