import React from "react";
import "./Dropdown.css";
import cross from "../../assets/failStatus.svg";

const CeDropdown = ({ options, onSelect, position, usedValues, onClose }) => {
   const handleClick = (event, option, type) => {
      event.stopPropagation();
      onSelect(option, type);
   };

   const isUsed = (option) => usedValues.includes(option);

   return (
      <div
         className="ce-dropdown"
         style={{
            position: "absolute",
            top: position.top,
            left: position.left,
         }}
      >
         <div
            style={{
               marginTop: "8px",
               display: "flex",
               alignItems: "center",
               justifyContent: "space-between",
            }}
            className="info-text-cont"
         >
            <div style={{ marginLeft: "4px", color: "black" }}>Variables</div>
            <img onClick={onClose} className="c-img" src={cross} alt="close" />
         </div>
         <ul className="ce-dropdown-list">
            {options.input_arg.map((option, index) => (
               <li
                  key={index}
                  className="ce-dropdown-item"
                  style={{ opacity: isUsed(option) ? 0.5 : 1 }}
                  onMouseEnter={(e) => (e.target.style.opacity = 1)}
                  onMouseLeave={(e) =>
                     (e.target.style.opacity = isUsed(option) ? 0.5 : 1)
                  }
                  onClick={(e) => handleClick(e, option, "input_arg")}
               >
                  <span style={{ color: "red" }}>*</span> {option}
               </li>
            ))}
            {options.output_arg.map((option, index) => (
               <li
                  key={index}
                  className="ce-dropdown-item"
                  style={{ opacity: isUsed(option) ? 0.5 : 1 }}
                  onMouseEnter={(e) => (e.target.style.opacity = 1)}
                  onMouseLeave={(e) =>
                     (e.target.style.opacity = isUsed(option) ? 0.5 : 1)
                  }
                  onClick={(e) => handleClick(e, option, "output_arg")}
               >
                  {option}
               </li>
            ))}
         </ul>
      </div>
   );
};

export default CeDropdown;
