import { Tooltip } from "@mui/material";
import { useEffect } from "react";
import CeDropdown from "./Dropdown";

const EditableSpan = ({
   words,
   setWords,
   handleRefreshWords,
   handleShowDropdown,
   handleHideDropdown,
   visibleDropdownIndex,
   dropDownValues,
   usedValues,
   handleSelect,
}) => {
   const handleWordEdit = (index, newWord) => {
      const updatedWords = [...words];
      const trimmedWord = newWord.trim();

      if (trimmedWord === "") {
         updatedWords.splice(index, 1);
      } else {
         updatedWords[index].word = trimmedWord;
      }

      if (trimmedWord.includes(" ")) {
         handleRefreshWords();
      } else {
         setWords(updatedWords);
      }
   };

   const handleFocus = (index, event) => {
      if (words[index].hasOwnProperty("arg_name")) {
         handleShowDropdown(index);
      } else {
         handleHideDropdown();
      }
   };

   const handleBlur = (index, event) => {
      // Prevent unintended text changes by checking if the dropdown is visible
      if (visibleDropdownIndex !== index) {
         handleWordEdit(index, event.target.textContent.trim());
      }
      if (words[index].hasOwnProperty("arg_name")) {
         // handleHideDropdown();
      }
   };

   const handleDoubleClick = (index, event) => {
      event.preventDefault();
      const updatedWords = [...words];
      if (updatedWords[index].hasOwnProperty("arg_name")) {
         delete updatedWords[index].arg_name;
      } else {
         updatedWords[index].arg_name = null;
         handleShowDropdown(index);
      }
      setWords(updatedWords);
   };

   const handleMouseDown = (event) => {
      if (event.detail > 1) {
         event.preventDefault();
      }
   };

   useEffect(() => {
      document.addEventListener("mousedown", handleMouseDown);
      return () => {
         document.removeEventListener("mousedown", handleMouseDown);
      };
   }, []);

   return words.map((wordObj, index) => {
      let className = "instruction-word-container";
      if (wordObj.hasOwnProperty("arg_name")) {
         className =
            wordObj.arg_name === null
               ? "instruction-word-container-empty-variable"
               : "instruction-word-container-existing-variable";
      }
      if (wordObj.hasOwnProperty("highlight")) {
         className += wordObj.highlight ? " highlight" : "";
      }

      return (
         <span
            key={index}
            className={className}
            contentEditable
            suppressContentEditableWarning
            spellCheck={false}
            onBlur={(e) => handleBlur(index, e)}
            onFocus={(e) => handleFocus(index, e)}
            onDoubleClick={(e) => handleDoubleClick(index, e)}
            onMouseDown={handleMouseDown}
            style={{ userSelect: "none", position: "relative" }}
         >
            <Tooltip title={wordObj.arg_name} placement="top">
               {wordObj.word}
            </Tooltip>
            {visibleDropdownIndex === index && (
               <CeDropdown
                  options={dropDownValues}
                  onSelect={handleSelect}
                  position={{ top: "25px", left: 0 }}
                  usedValues={usedValues}
                  onClose={handleHideDropdown}
               />
            )}
         </span>
      );
   });
};

export default EditableSpan;
