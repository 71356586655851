import React from "react";
import "./changedPassword.css";
import "./reset.css";
import logo from "../../assets/BotGauge-logo.png";
import done from "../../assets/done.png";
import { Link } from "react-router-dom";

const Changed = () => {
   return (
      <>
         <div className="changed-password parent-reset">
            <div className="left-reset">
               <div className="main-reset">Automate Your Web App Testing</div>
               <div className="sub-reset">
                  Ultimate automatic testing framework tailored for web apps
                  across multiple browsers.
                  <br />
                  Ensure consistency, efficiency, and quality like never before.
               </div>
            </div>
            <div className="right-reset">
               <div className="changed-logo-reset">
                  <img
                     className="botgauge-logo-changed-password"
                     src={logo}
                     alt="BotGauge-logo"
                  />
                  <img
                     className="done-logo-changed-password"
                     src={done}
                     alt="check-logo-done"
                  />
                  <div className="changed-password-info">
                     Your password has been successfully changed.
                  </div>
                  <button className="go-back-successfull-password-changed">
                     <a
                        className="change-reset-success-back-to-login"
                        href="/login"
                     >
                        {" "}
                        CONTINUE TO LOGIN{" "}
                     </a>
                  </button>
               </div>
            </div>
         </div>
      </>
   );
};

export default Changed;
