import {
   RESET_PRIVILEGES,
   SET_PRIVILEGES,
} from "../constants/priveleges-types";

const initialState = {
   userPrivileges: {
      viewGeneralSettings: false,
      editGeneralSettings: false,
      viewCompanyManagement: false,
      createUsers: {
         admin: false,
         super_admin: false,
         user: false,
      },
      viewEditPresentAI: false,
   },
};

const mergePrivileges = (initialPrivileges, newPrivileges) => {
   const mergedPrivileges = { ...initialPrivileges };

   for (const [key, value] of Object.entries(newPrivileges)) {
      if (key === "createUsers" && typeof value === "object") {
         mergedPrivileges.createUsers = {
            ...mergedPrivileges.createUsers,
            ...value,
         };
      } else if (key in mergedPrivileges) {
         mergedPrivileges[key] = value;
      }
   }

   return mergedPrivileges;
};

export const userPrivilegesReducer = (state = initialState, action) => {
   switch (action.type) {
      case SET_PRIVILEGES:
         return {
            ...state,
            userPrivileges: mergePrivileges(
               state.userPrivileges,
               action.payload
            ),
         };

      case RESET_PRIVILEGES:
         return initialState;

      default:
         return state;
   }
};

export default userPrivilegesReducer;
