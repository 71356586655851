import React from "react";
import {
   LineChart,
   Line,
   XAxis,
   YAxis,
   CartesianGrid,
   Tooltip,
   ResponsiveContainer,
} from "recharts";
import Container from "react-bootstrap/Container";
import "./dashboard.css";
import Card from "antd/lib/card";
import Table from "antd/lib/table";
import comt from "../../assets/dashboard/comthread.svg";
import exet from "../../assets/dashboard/exethread.svg";
import exe from "../../assets/dashboard/execution.svg";
import test from "../../assets/dashboard/test-case.svg";

const Dashboard = () => {
   // Mock Data
   const MockData = [
      {
         id: "1",
         month: "Jan",
         compilation: 12,
         execution: 20,
      },
      {
         id: "2",
         month: "Feb",
         compilation: 75,
         execution: 55,
      },
      {
         id: "3",
         month: "Mar",
         compilation: 45,
         execution: 50,
      },
      {
         id: "4",
         month: "Apr",
         compilation: 25,
         execution: 37,
      },
      {
         id: "5",
         month: "May",
         compilation: 50,
         execution: 45,
      },
      {
         id: "6",
         month: "Jun",
         compilation: 75,
         execution: 64,
      },
      {
         id: "7",
         month: "Jul",
         compilation: 45,
         execution: 50,
      },
      {
         id: "8",
         month: "Aug",
         compilation: 25,
         execution: 37,
      },
      {
         id: "9",
         month: "Sep",
         compilation: 55,
         execution: 50,
      },
      {
         id: "10",
         month: "Oct",
         compilation: 75,
         execution: 64,
      },
      {
         id: "11",
         month: "Nov",
         compilation: 25,
         execution: 40,
      },
      {
         id: "12",
         month: "Dec",
         compilation: 50,
         execution: 73,
      },
   ];

   const columns = [
      {
         title: <div className="column-title ">Test case</div>,
         dataIndex: "testCase",
         key: "testCase",
      },
      {
         title: <div className="column-title ">Status</div>,
         dataIndex: "status",
         key: "status",
      },
      {
         title: <div className="column-title ">Browser</div>,
         dataIndex: "browser",
         key: "browser",
      },
      {
         title: <div className="column-title ">Executed At</div>,
         dataIndex: "executedAt",
         key: "executedAt",
      },
   ];

   const data = [];

   return (
      <Container>
         <div className="App">
            <div className="row card-row">
               {[
                  "Test Cases",
                  "Executions",
                  "Compilation Threads",
                  "Execution Threads",
               ].map((title, i) => {
                  const colors = ["#2654BB", "#10B981", "#FF7A00", "#10B981"];
                  const icons = [test, exe, comt, exet];
                  return (
                     <Card key={i} style={{ width: " 296px" }}>
                        <div className="card-content">
                           <div
                              className="rectangle"
                              style={{
                                 background: colors[i % colors.length],
                                 marginRight: "10px",
                              }}
                           ></div>
                           <div className="card-title">
                              <span>{title}</span>
                              <p>0</p>
                           </div>
                           <div style={{ marginLeft: "auto" }}>
                              {" "}
                              <img src={icons[i % icons.length]} alt="icon" />
                           </div>
                        </div>
                     </Card>
                  );
               })}
            </div>
            <div className="row">
               <h2 className="h2">Recent Executions</h2>
               <Table className="table" columns={columns} dataSource={data} />
            </div>
            <div style={{ height: "561px" }} className="row">
               <h2 className="h2">Threads Usage</h2>

               <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                     width={500}
                     height={300}
                     data={MockData}
                     margin={{
                        top: 15,
                        right: 30,
                        left: 20,
                        bottom: 5,
                     }}
                  >
                     <CartesianGrid
                        vertical={false}
                        yAxis={false}
                        strokeWidth={1}
                        stroke="#9E9E9E"
                     />
                     <XAxis tickLine={false} dataKey="month" stroke="#9E9E9E" />
                     <YAxis axisLine={false} stroke="#9E9E9E" />
                     <Tooltip />
                     <Line
                        type="natural"
                        dataKey="compilation"
                        stroke="#0036AF"
                        strokeWidth={4}
                        dot={{ fill: "#0036AF", r: 4 }}
                        activeDot={{ r: 8 }}
                     />
                     <Line
                        type="natural"
                        dataKey="execution"
                        stroke="#10B981"
                        dot={{ fill: "#10B981", r: 4 }}
                        strokeWidth={4}
                        activeDot={{ r: 8 }}
                     />
                  </LineChart>
               </ResponsiveContainer>

               <div
                  className="custom-legend"
                  style={{
                     marginBottom: "30px",
                     width: "100%",
                     fontSize: "16px",
                     color: "#9E9E9E",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     gap: "30px",
                  }}
               >
                  <div
                     className="legend-item"
                     style={{ display: "flex", alignItems: "center" }}
                  >
                     <div
                        className="legend-icon compilation"
                        style={{
                           width: "24px",
                           height: "24px",
                           backgroundColor: "#0036AF",
                           borderRadius: "2px",
                           marginRight: "5px",
                        }}
                     ></div>
                     <span>Compilation Thread</span>
                  </div>
                  <div
                     className="legend-item"
                     style={{ display: "flex", alignItems: "center" }}
                  >
                     <div
                        className="legend-icon execution"
                        style={{
                           width: "24px",
                           height: "24px",
                           backgroundColor: "#10B981",
                           borderRadius: "2px",
                           marginRight: "5px",
                        }}
                     ></div>
                     <span>Execution Thread</span>
                  </div>
               </div>
            </div>
         </div>
      </Container>
   );
};

export default Dashboard;
