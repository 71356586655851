export const SET_TESTCASES = {
   SET_TESTCASES: "SET_TESTCASES",
};

export const RESET_TESTCASES = {
   RESET_TESTCASES: "RESET_TESTCASES",
};

export const SET_EDITING_STEP_ON = "SET_EDITING_STEP_ON";
export const SET_ADDING_STEP_ON = "SET_ADDING_STEP_ON";
