let title = "";
let observers = [];

const notifyObservers = () => {
   observers.forEach((observer) => observer());
};

export const getTitle = (isVolatile = false) => {
   if (title === "" && !isVolatile) {
      title = localStorage.getItem("title") || "";
      return title;
   } else {
      return title;
   }
};

export const setTitle = (value, isVolatile = false) => {
   title = value;
   !isVolatile && localStorage.setItem("title", title);
   notifyObservers();
};

export const addObserver = (observer) => {
   observers.push(observer);
};

export const removeObserver = (observer) => {
   observers = observers.filter((item) => item !== observer);
};
