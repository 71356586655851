import React, { useState, useEffect, useRef } from "react";
import navac from "../../assets/Top Nav ac.svg";
import "./ProfileDropdown.css";

import settings from "../../assets/user-settings.svg";
import upgradePlan from "../../assets/user-upgrade-plan.svg";
import Logout from "../../assets/user-logout.svg";
import Problem from "../../assets/user-alert.svg";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../services/settingServices";
import { fetchuserinfo } from "../../services/assetServices";

const ProfileDropdown = () => {
   const { logout } = useAuth();
   const navigate = useNavigate();

   const [isOpen, setIsOpen] = useState(false);
   const [userData, setUserData] = useState(null);
   const dropdownRef = useRef(null);

   const toggleDropdown = () => {
      setIsOpen(!isOpen);
   };

   const fetchinfo = async () => {
      try {
         const data = await fetchuserinfo();
         setUserData(data);
      } catch (error) {
         console.error("Error fetching user info", error);
      }
   };

   const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
         setIsOpen(false);
      }
   };

   const handleCloseDropdown = () => {
      setIsOpen(false);
   };

   const handleLogOut = async () => {
      handleCloseDropdown();
      console.log("Logging out");
      logout();
   };

   const handleSettingClick = () => {
      handleCloseDropdown();
      navigate("/settings");
   };

   const getuserdata = async () => {
      try {
         let userdata = await getUser();
         // console.log(userdata);
      } catch (error) {
         console.error("Error fetching user data", error);
      }
   };

   useEffect(() => {
      getuserdata();
      fetchinfo();

      if (isOpen) {
         document.addEventListener("mousedown", handleClickOutside);
      } else {
         document.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, [isOpen]);

   return (
      <div className="profile-container" ref={dropdownRef}>
         <img
            height={24}
            width={24}
            style={{ margin: "0 28px" }}
            src={navac}
            alt="icon"
            onClick={toggleDropdown}
            className="profile-icon"
         />
         {isOpen && (
            <div className="profile-dropdown-container">
               <div className="profile-header">
                  <div className="profile-header-detail">
                     {userData ? (
                        <>
                           <h4>{`${userData.first_name} ${userData.last_name}`}</h4>
                           <p>{userData.email}</p>
                        </>
                     ) : (
                        <>
                           <h4>Loading...</h4>
                           <p>Loading...</p>
                        </>
                     )}
                  </div>
                  <div className="profile-header-img">
                     <img height={30} width={30} src={navac} alt="icon" />
                  </div>
               </div>

               <div className="profile-main">
                  <div
                     onClick={handleSettingClick}
                     className="profile-dropdown-item"
                  >
                     <img src={settings} alt="settings" />
                     Settings
                  </div>
                  <div className="profile-dropdown-item">
                     <img src={Problem} alt="report problem" />
                     Report a problem
                  </div>
                  <div className="profile-dropdown-item special">
                     <img src={upgradePlan} alt="upgrade plan" />
                     Upgrade plan
                  </div>
               </div>

               <div className="profile-footer">
                  <div
                     className="profile-dropdown-item logout"
                     onClick={handleLogOut}
                  >
                     <img src={Logout} alt="logout" />
                     Logout
                  </div>
               </div>
            </div>
         )}
      </div>
   );
};

export default ProfileDropdown;
