import React from "react";
import "./reset.css";
import logo from "../../assets/BotGauge-logo.png";

const reset = () => {
   return (
      <>
         <div className="parent-reset">
            <div className="left-reset">
               <div className="main-reset">
                  Automate Your <br /> Web App Testing
               </div>
               <div className="sub-reset">
                  Ultimate automatic testing framework tailored for web apps
                  across multiple browsers.
                  <br />
                  Ensure consistency, efficiency, and quality like never before.
               </div>
            </div>
            <div className="right-reset">
               <div className="logo-reset">
                  <img src={logo} alt="BotGauge-logo" />
               </div>

               <form className="new-password-form">
                  <div className="text-container">
                     <div className="font-reset">Enter New Password</div>
                     <input
                        type="password"
                        className="input-password-new"
                     ></input>
                  </div>
                  <div className="text-container">
                     <div className="font-reset">Re Enter New Password</div>
                     <input
                        type="password"
                        className="input-password-new"
                     ></input>
                  </div>

                  <button className="reset-password-button">
                     RESET YOUR PASSWORD
                  </button>
               </form>
            </div>
         </div>
      </>
   );
};

export default reset;
