import apiService from "./apiServices";

export const get_user_privileges = async () => {
   try {
      const response = await apiService.get(`/platform/v1/user-privileges`);
      return response.data;
   } catch (error) {
      console.error("Error fetching user data:", error);
   }
};
