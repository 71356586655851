// ThreadTypes.js

// Action types for total threads
export const SET_TOTAL_THREADS = "SET_TOTAL_THREADS";

// Action types for allocated compilation threads
export const SET_ALLOCATED_COMPILATION_THREADS =
   "SET_ALLOCATED_COMPILATION_THREADS";

// Action types for allocated execution threads
export const SET_ALLOCATED_EXECUTION_THREADS =
   "SET_ALLOCATED_EXECUTION_THREADS";

// Action types for used compilation threads
export const SET_USED_COMPILATION_THREADS = "SET_USED_COMPILATION_THREADS";

// Action types for used execution threads
export const SET_USED_EXECUTION_THREADS = "SET_USED_EXECUTION_THREADS";

// Action types for dynamic threads
export const SET_DYNAMIC_THREADS = "SET_DYNAMIC_THREADS";
