import React, { createContext, useContext, useState } from "react";
import useWebSocket from "react-use-websocket";
import { useLocation } from "react-router-dom";
import { apiWS } from "../../../services/apiServices";

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
   const location = useLocation();
   const [testSessionId, setTestSessionId] = useState(null);
   const [sessionId, setSessionId] = useState(null);
   const [contentLoading, setContentLoading] = useState(false);

   // Handle incoming messages here
   const handleMessage = (event) => {
      if (event.data) {
         try {
            const messageData = JSON.parse(event.data);
            if (messageData.data_type && messageData.data_type === 100) {
               setContentLoading(null);
               setSessionId(testSessionId);
               setTimeout(() => {
                  setTestSessionId(null);
               }, 0);
            }
            return;
         } catch (error) {
            throw new Error("Invalid message from server");
         }
      }
   };

   const authToken = localStorage.getItem("authToken");

   const { sendJsonMessage, getWebSocket } = useWebSocket(
      testSessionId ? `wss://${apiWS}/platform/ws?token=${authToken}` : null,
      {
         onOpen: () => {
            if (testSessionId) {
               !contentLoading && setContentLoading(true);
               sendJsonMessage({
                  data_type: 0,
                  session_id: testSessionId,
               });
            }
         },
         shouldReconnect: (closeEvent) => {
            if (closeEvent && !closeEvent.wasClean) {
               console.log("Connection closed unexpectedly. Reconnecting...");
               setContentLoading(false);
               return true;
            } else {
               console.log("Connection closed. No reconnection attempts.");
               return false;
            }
         },
         onMessage: handleMessage,
      }
   );

   // Handle sessionId on change
   // useEffect(() => {
   //   if (testSessionId) {
   //     console.log(`Connecting to WebSocket with session ID: ${testSessionId}`);
   //     return () => {
   //       console.log(
   //         `Cleaning up WebSocket connection for session ID: ${testSessionId}`
   //       );
   //       const ws = getWebSocket();
   //       // if (ws) ws.close();
   //     };
   //   }
   //   }, [testSessionId, getWebSocket]);

   return (
      <WebSocketContext.Provider
         value={{
            sessionId,
            setSessionId,
            sendJsonMessage,
            getWebSocket,
            contentLoading,
            setContentLoading,
            setTestSessionId,
         }}
      >
         {children}
      </WebSocketContext.Provider>
   );
};

export const useWebSocketContext = () => {
   return useContext(WebSocketContext);
};
