import { useState } from "react";

import "./select.css";

const Checkbox = ({ children, checked, onChange }) => (
   <label style={{ marginRight: "1em" }}>
      <input type="checkbox" checked={checked} onChange={onChange} />
      {children}
   </label>
);

const CheckBox = ({ select, selecthandler, value }) => {
   return (
      <div className="parent-cont-os">
         <div className="toggle-button">
            <Checkbox
               checked={select}
               onChange={() => selecthandler((state) => !state)}
            />
            <div className="text-select">{value}</div>
         </div>
      </div>
   );
};

export default CheckBox;
