import {
   RESET_TESTCASES,
   SET_ADDING_STEP_ON,
   SET_EDITING_STEP_ON,
   SET_TESTCASES,
} from "../constants/presence-ai-types";

const initialState = {
   testcases: [],
   groups: [],
   globalData: [],
   editingStepOn: null,
   addingStepOn: null,

   editingGroupOn: null,
   addingGroupOn: null,

   loadingTestcases: false,
};

const deleteCard = (cards, deletedCardStepId) => {
   const deleteAndUpdateIds = (cardsArray, parentId = "") => {
      let nextIndex = 1;
      return cardsArray.reduce((acc, card) => {
         if (card.step_id === deletedCardStepId) {
            return acc; // Skip this card as it's being deleted
         }

         const currentParts = card.step_id.split(".");
         const newParts = parentId
            ? [...parentId.split("."), nextIndex.toString()]
            : [nextIndex.toString()];
         const newStepId = newParts.join(".");

         nextIndex++;

         const updatedCard = { ...card, step_id: newStepId };

         if (updatedCard.sub_steps && updatedCard.sub_steps.length > 0) {
            updatedCard.sub_steps = deleteAndUpdateIds(
               updatedCard.sub_steps,
               newStepId
            );
         }

         return [...acc, updatedCard];
      }, []);
   };

   return deleteAndUpdateIds(cards);
};
const updateCard = (cards, step_id, updateData) => {
   return cards.map((card) => {
      if (card.step_id === step_id) {
         let updatedCard = {
            ...card,
            inst: {
               ...card.inst,
               ...updateData.inst,
            },
            data: updateData.data,
            status: updateData.status,
            ordinal: updateData.ordinal,
            message: updateData.message,
            do_ordinal_selection: updateData.do_ordinal_selection,
         };

         if (updateData.group_id) {
            updatedCard.group_id = updateData.group_id;
         }
         if (updateData.group_hash) {
            updatedCard.group_hash = updateData.group_hash;
         }

         return updatedCard;
      }

      if (card.sub_steps) {
         return {
            ...card,
            sub_steps: updateCard(card.sub_steps, step_id, updateData),
         };
      }

      return card;
   });
};

const createGroupMap = (groups) => {
   return groups.reduce((map, group) => {
      map[group.group_name.toLowerCase()] = group.steps;
      return map;
   }, {});
};
const updateStepIds = (steps, parentId) => {
   return steps.map((step, index) => ({
      ...step,
      step_id: `${parentId}.${index + 1}`,
      sub_steps: step.sub_steps
         ? updateStepIds(step.sub_steps, `${parentId}.${index + 1}`)
         : undefined,
   }));
};
const syncGroupsWithTestcases = (state) => {
   const groupMap = createGroupMap(state.groups);

   const updatedTestcases = state.testcases.map((testcase) => {
      const updateSteps = (steps) => {
         return steps.map((step) => {
            if (
               step.inst.instr_type === 400 &&
               groupMap[step.inst.instr.toLowerCase()]
            ) {
               return {
                  ...step,
                  sub_steps: updateStepIds(
                     groupMap[step.inst.instr.toLowerCase()],
                     step.step_id
                  ),
               };
            }
            return {
               ...step,
               sub_steps: step.sub_steps
                  ? updateSteps(step.sub_steps)
                  : undefined,
            };
         });
      };

      return {
         ...testcase,
         test_steps_json: updateSteps(testcase.test_steps_json),
      };
   });

   return {
      ...state,
      testcases: updatedTestcases,
   };
};

function filterGlobalDataStep(testcases) {
   const globalData = [];

   testcases.forEach((testcase) => {
      testcase.test_steps_json.forEach((step) => {
         // Check if the step's data_type is 1
         if (step.data && step.data.data_type === 1) {
            globalData.push(step.data);
         }

         // Check sub_steps if they exist
         if (step.sub_steps) {
            step.sub_steps.forEach((subStep) => {
               if (subStep.data && subStep.data.data_type === 1) {
                  globalData.push(subStep.data);
               }
            });
         }
      });
   });

   return globalData;
}

const presenceAiReducer = (state = initialState, action) => {
   switch (action.type) {
      case "SET_P_AI_GROUPS":
         return {
            ...state,
            groups: action.payload,
         };

      case SET_TESTCASES:
         return {
            ...state,
            testcases: action.payload,
         };

      case RESET_TESTCASES:
         return {
            ...state,
            testcases: [],
         };

      case SET_EDITING_STEP_ON:
         return {
            ...state,
            editingStepOn: action.payload,
         };

      case SET_ADDING_STEP_ON:
         return {
            ...state,
            addingStepOn: action.payload,
         };

      case "SET_ADDING_P_AI_GROUP_ON":
         return {
            ...state,
            addingGroupOn: action.payload,
         };

      case "SET_EDITING_P_AI_GROUP_ON":
         return {
            ...state,
            editingGroupOn: action.payload,
         };

      case "ADD_P_AI_CARD":
         console.log("ADD_CARDQ", action.payload);
         const {
            inst = {
               instr_type: null,
               instr: "",
               label_type: null,
               label: "",
               contextual_label_type: null,
               contextual_label: "",
            },
            step_id,
            testcaseIndex,
            data,
            status,
            ordinal,
            do_ordinal_selection,
            sub_steps = [],
            message,
         } = action.payload;

         const cardDetails = {
            inst,
            step_id,
            data,
            status,
            ordinal,
            do_ordinal_selection,
            sub_steps,
            parent_id: null,
            message,
         };
         if (action.payload.group_id) {
            cardDetails.group_id = action.payload.group_id;
         }
         if (action.payload.group_hash) {
            cardDetails.group_hash = action.payload.group_hash;
         }

         const addCardToHierarchy = (cards, newCard) => {
            const stepIdParts = newCard.step_id.split(".");
            const parentStepId = stepIdParts.slice(0, -1).join(".");
            const currentStepId = stepIdParts.join(".");

            if (parentStepId === "") {
               // This is a top-level card
               return insertCard(cards, newCard);
            }

            return cards.map((card) => {
               if (card.step_id === parentStepId) {
                  const updatedCard = {
                     ...card,
                     sub_steps: insertCard(
                        card.sub_steps ? [...card.sub_steps] : [],
                        newCard
                     ),
                  };
                  newCard.parent_id = parentStepId;
                  return updatedCard;
               }

               if (card.sub_steps && card.sub_steps.length > 0) {
                  return {
                     ...card,
                     sub_steps: addCardToHierarchy(card.sub_steps, newCard),
                  };
               }

               return card;
            });
         };

         const insertCard = (cards, newCard) => {
            const newCardId = parseInt(newCard.step_id.split(".").pop(), 10);
            const insertIndex = cards.findIndex((card) => {
               const cardId = parseInt(card.step_id.split(".").pop(), 10);
               return cardId >= newCardId;
            });

            if (insertIndex === -1) {
               return [...cards, newCard];
            } else {
               const newCards = [
                  ...cards.slice(0, insertIndex),
                  newCard,
                  ...cards.slice(insertIndex),
               ];
               return adjustStepIds(newCards, insertIndex);
            }
         };

         const adjustStepIds = (cards, startIndex) => {
            return cards.map((card, index) => {
               if (index >= startIndex) {
                  const stepIdParts = card.step_id.split(".");
                  stepIdParts[stepIdParts.length - 1] = (index + 1).toString();
                  const newStepId = stepIdParts.join(".");

                  return {
                     ...card,
                     step_id: newStepId,
                     sub_steps: adjustSubStepIds(
                        card.sub_steps ? [...card.sub_steps] : [],
                        newStepId
                     ),
                  };
               }
               return card;
            });
         };

         const adjustSubStepIds = (subSteps, parentStepId) => {
            return subSteps.map((subStep, index) => {
               const newSubStepId = `${parentStepId}.${index + 1}`;

               return {
                  ...subStep,
                  step_id: newSubStepId,
                  sub_steps: adjustSubStepIds(
                     subStep.sub_steps ? [...subStep.sub_steps] : [],
                     newSubStepId
                  ),
               };
            });
         };

         return {
            ...state,
            testcases: state.testcases.map((testcase, index) => {
               if (index === testcaseIndex) {
                  return {
                     ...testcase,
                     test_steps_json: addCardToHierarchy(
                        testcase.test_steps_json,
                        cardDetails
                     ),
                  };
               }
               return testcase;
            }),
         };

      case "EDIT_P_AI_CARD":
         return {
            ...state,
            testcases: state.testcases.map((testcase, index) => {
               if (index === action.payload.testcaseIndex) {
                  return {
                     ...testcase,
                     test_steps_json: updateCard(
                        testcase.test_steps_json,
                        action.payload.step_id,
                        action.payload
                     ),
                  };
               }
               return testcase;
            }),
         };

      case "DELETE_P_AI_CARD":
         console.log("DELETE_CARD", action.payload);
         // const { step_id, testcaseIndex } = action.payload;
         const { step_id: cardStepId, testcaseIndex: tcIndex } = action.payload;
         return {
            ...state,
            testcases: state.testcases.map((testcase, index) => {
               if (index === tcIndex) {
                  return {
                     ...testcase,
                     test_steps_json: deleteCard(
                        testcase.test_steps_json,
                        cardStepId
                     ),
                  };
               }
               return testcase;
            }),
         };

      case "SET_P_AI_LOADING_TESTCASES":
         return {
            ...state,
            loadingTestcases: action.payload,
         };

      case "ADD_P_AI_GROUP_CARD":
         console.log("ADD_GROUP_CARD", action.payload);
         const {
            inst: groupInst = {
               instr_type: null,
               instr: "",
               label_type: null,
               label: "",
               contextual_label_type: null,
               contextual_label: "",
            },
            step_id: groupStepId,
            groupIndex,
            data: groupData,
            status: groupStatus,
            ordinal: groupOrdinal,
            do_ordinal_selection: groupDoOrdinalSelection,
            sub_steps: groupSubSteps = [],
            message: groupMessage,
         } = action.payload;

         const groupCardDetails = {
            inst: groupInst,
            step_id: groupStepId,
            data: groupData,
            status: groupStatus,
            ordinal: groupOrdinal,
            do_ordinal_selection: groupDoOrdinalSelection,
            sub_steps: groupSubSteps,
            parent_id: null,
            message: groupMessage,
         };

         if (action.payload.group_id) {
            groupCardDetails.group_id = action.payload.group_id;
         }
         if (action.payload.group_hash) {
            groupCardDetails.group_hash = action.payload.group_hash;
         }

         const addCardToGroupHierarchy = (steps, newCard) => {
            const stepIdParts = newCard.step_id.split(".");
            const parentStepId = stepIdParts.slice(0, -1).join(".");
            const currentStepId = stepIdParts.join(".");

            if (parentStepId === "") {
               // This is a top-level card
               return insertGroupCard(steps, newCard);
            }

            return steps.map((step) => {
               if (step.step_id === parentStepId) {
                  const updatedStep = {
                     ...step,
                     sub_steps: insertGroupCard(
                        step.sub_steps ? [...step.sub_steps] : [],
                        newCard
                     ),
                  };
                  newCard.parent_id = parentStepId;
                  return updatedStep;
               }

               if (step.sub_steps && step.sub_steps.length > 0) {
                  return {
                     ...step,
                     sub_steps: addCardToGroupHierarchy(
                        step.sub_steps,
                        newCard
                     ),
                  };
               }

               return step;
            });
         };

         const insertGroupCard = (steps, newCard) => {
            const newCardId = parseInt(newCard.step_id.split(".").pop(), 10);
            const insertIndex = steps.findIndex((step) => {
               const stepId = parseInt(step.step_id.split(".").pop(), 10);
               return stepId >= newCardId;
            });

            if (insertIndex === -1) {
               return [...steps, newCard];
            } else {
               const newSteps = [
                  ...steps.slice(0, insertIndex),
                  newCard,
                  ...steps.slice(insertIndex),
               ];
               return adjustGroupStepIds(newSteps, insertIndex);
            }
         };

         const adjustGroupStepIds = (steps, startIndex) => {
            return steps.map((step, index) => {
               if (index >= startIndex) {
                  const stepIdParts = step.step_id.split(".");
                  stepIdParts[stepIdParts.length - 1] = (index + 1).toString();
                  const newStepId = stepIdParts.join(".");

                  return {
                     ...step,
                     step_id: newStepId,
                     sub_steps: adjustGroupSubStepIds(
                        step.sub_steps ? [...step.sub_steps] : [],
                        newStepId
                     ),
                  };
               }
               return step;
            });
         };

         const adjustGroupSubStepIds = (subSteps, parentStepId) => {
            return subSteps.map((subStep, index) => {
               const newSubStepId = `${parentStepId}.${index + 1}`;

               return {
                  ...subStep,
                  step_id: newSubStepId,
                  sub_steps: adjustGroupSubStepIds(
                     subStep.sub_steps ? [...subStep.sub_steps] : [],
                     newSubStepId
                  ),
               };
            });
         };

         return {
            ...state,
            groups: state.groups.map((group, index) => {
               if (index === groupIndex) {
                  return {
                     ...group,
                     steps: addCardToGroupHierarchy(
                        group.steps,
                        groupCardDetails
                     ),
                  };
               }
               return group;
            }),
         };

      case "EDIT_P_AI_GROUP_CARD":
         return {
            ...state,
            groups: state.groups.map((group, index) => {
               if (index === action.payload.groupIndex) {
                  return {
                     ...group,
                     steps: updateCard(
                        group.steps,
                        action.payload.step_id,
                        action.payload
                     ),
                  };
               }
               return group;
            }),
         };

      case "DELETE_P_AI_GROUP_CARD":
         console.log("DELETE_GROUP_CARD", action.payload);
         const {
            step_id: groupCardStepId,
            groupIndex: deletingFromGroupIndex,
         } = action.payload;
         return {
            ...state,
            groups: state.groups.map((group, index) => {
               if (index === deletingFromGroupIndex) {
                  const updatedSteps = group.steps.filter(
                     (step) => step.step_id !== groupCardStepId
                  );
                  return {
                     ...group,
                     steps: updatedSteps.map((step, idx) => ({
                        ...step,
                        step_id: `${idx + 1}`,
                        ordinal: idx + 1,
                     })),
                  };
               }
               return group;
            }),
         };

      case "SYNC_GROUPS_WITH_TESTCASES":
         return syncGroupsWithTestcases(state);

      case "DELETE_P_AI_TESTCASE":
         return {
            ...state,
            testcases: state.testcases.filter(
               (testcase, index) => index !== action.payload
            ),
         };

      case "FILTER_P_AI_GLOBAL_DATA":
         if (!state.testcases.length) return state;

         const filteredGlobalData = filterGlobalDataStep(state.testcases);

         console.log("FILTERED GLOBAL DATA", filteredGlobalData);
         return {
            ...state,
            globalData: [...filteredGlobalData],
         };

      default:
         return state;
   }
};

export default presenceAiReducer;
