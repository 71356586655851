import { SET_IS_ONLINE } from "../constants/is-online-types";

const initialState = {
   isOnline: true,
};

export const isUserOnlineReducer = (state = initialState, action) => {
   switch (action.type) {
      case SET_IS_ONLINE:
         return {
            ...state,
            isOnline: action.payload,
         };

      default:
         return state;
   }
};

export default isUserOnlineReducer;
