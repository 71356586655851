import {
   RESET_TESTCASES,
   SET_ADDING_STEP_ON,
   SET_EDITING_STEP_ON,
   SET_TESTCASES,
} from "../constants/presence-ai-types";

export const set_presence_ai_groups = (array) => {
   return {
      type: "SET_P_AI_GROUPS",
      payload: array,
   };
};

export const set_presence_ai_testcases = (array) => {
   return {
      type: SET_TESTCASES,
      payload: array,
   };
};

export const reset_presence_ai_testcases = () => {
   return {
      type: RESET_TESTCASES,
   };
};

export const set_editing_step_on = (stepData) => ({
   type: SET_EDITING_STEP_ON,
   payload: stepData,
});

export const set_adding_step_on = (stepData) => ({
   type: SET_ADDING_STEP_ON,
   payload: stepData,
});

export const delete_p_ai_card = (step_data) => {
   return {
      type: "DELETE_P_AI_CARD",
      payload: step_data,
   };
};

export const edit_p_ai_card = (cardData, testcaseIndex) => ({
   type: "EDIT_P_AI_CARD",
   payload: {
      ...cardData,
      testcaseIndex,
   },
});

export const add_p_ai_card = (cardData, testcaseIndex) => ({
   type: "ADD_P_AI_CARD",
   payload: {
      ...cardData,
      testcaseIndex,
   },
});

export const set_loading_testcases = (value) => ({
   type: "SET_P_AI_LOADING_TESTCASES",
   payload: value,
});

export const set_adding_group_on = (id) => ({
   type: "SET_ADDING_P_AI_GROUP_ON",
   payload: id,
});

export const set_editing_group_on = (id) => ({
   type: "SET_EDITING_P_AI_GROUP_ON",
   payload: id,
});

export const delete_p_ai_group_card = (step_data) => {
   return {
      type: "DELETE_P_AI_GROUP_CARD",
      payload: step_data,
   };
};

export const edit_p_ai_group_card = (cardData, groupIndex) => ({
   type: "EDIT_P_AI_GROUP_CARD",
   payload: {
      ...cardData,
      groupIndex,
   },
});

export const add_p_ai_group_card = (cardData, groupIndex) => ({
   type: "ADD_P_AI_GROUP_CARD",
   payload: {
      ...cardData,
      groupIndex,
   },
});

export const sync_p_ai_groups = () => ({
   type: "SYNC_GROUPS_WITH_TESTCASES",
});

export const delete_p_ai_testcases = (index) => {
   return {
      type: "DELETE_P_AI_TESTCASE",
      payload: index,
   };
};

export const filter_p_ai_global_data = () => {
   return {
      type: "FILTER_P_AI_GLOBAL_DATA",
   };
};
